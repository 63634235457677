<!-- 对账记录弹窗 -->
<template>
  <el-dialog
    width="50%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'对账记录':'对账记录'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      label-width="120px">

      <div>
        <div style="display: flex;align-items: center;margin-bottom: 25px;" v-for="(item,index) in list" :key="index">
          <div style="font-size: 16px;font-weight: 500;color: #050505;">{{item.time}}</div>
          <div style="width: 48px;height: 48px;background: #FFECCD;border-radius: 50%;margin-left: 20px;display: flex;align-items: center;justify-content: center;">
            <div style="width: 24px;height: 24px;background: #FF9B05;border-radius: 50%;">
              <div class="xian" v-if="index != list.length -1"></div>
            </div>
          </div>
          <div style="margin-left: 20px;">
            <div style="font-size: 16px;font-weight: 500;color: #050505;">{{item.annotation}}</div>
            <div style="margin-top: 10px;">
              <span style="font-size: 14px;font-weight: 400;color: #878787;">不符原因:{{item.reason}}</span>
            </div>
            <div>
              <span style="font-size: 14px;font-weight: 400;color: #878787;">操作人:{{item.operator_name}}</span>
            </div>
          </div>
        </div>
        <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>
      </div>

    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

import {capacity_reconciliationreconciliation_log, reconciliation_log} from "@/api/finance";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      list:[
        // {
        //   time:'2022.09.18  14:30',
        //   title:'公司处理异常：价格由350修改为400',
        //   beizhu:'已修改价格',
        //   caozuoren:'同城救援  林侃'
        // },
        // {
        //   time:'2022.09.18  14:30',
        //   title:'公司确认',
        //   beizhu:'',
        //   caozuoren:'同城救援  林侃'
        // },
        // {
        //   time:'2022.09.18  14:30',
        //   title:'客户异常举证',
        //   beizhu:'大足大非',
        //   caozuoren:'同城救援  林侃'
        // },
      ],
      lastItem:0,

    };
  },
  watch: {
    // data() {
    //   if (this.data) {
    //     console.log(this.data)
    //     this.isUpdate = true;
    //   } else {
    //     this.form = {};
    //     this.isUpdate = false;
    //   }
    // }
  },

  mounted() {


    if (this.data) {
      console.log(this.data)
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }


    capacity_reconciliationreconciliation_log(this.data.id).then(res => {
      console.log(res);
      this.list = res.data;
      //给时间轴默认添加线
      this.list.forEach(item => {
        item.isXian = true;
      })
      //获取数组的最后一项
      this.lastItem = this.list.length - 1;
      this.list[this.lastItem].isXian = false;
      console.log(this.list);
    })
  },

  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">
.xian{
  width: 1px;
  height: 100px;
  border: 3px dashed #ff9b05;
  margin: auto;
}
</style>
